import { useState } from 'react';
import PhoneInput from 'react-phone-number-input'
import { sendEmail } from '../api';

function ContactForm(props) {
    const [phoneNumber, setPhoneNumber] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();
    const [message, setMessage] = useState();

    const submitEmail = () => {
        sendEmail({name: firstName + ' ' + lastName, company, email, phone: phoneNumber, message}).then((response) => {
            alert('Mesajul dumneavoastra a fost trimis!')
        }).catch((error) => {
            console.log(error);
        })
    };
    
    return (
        <div className={"contact-form-wrapper"}>
            <div className={"input-wrapper"}>
                <label htmlFor={"secondName"}>Nume:</label>
                <input className={"input"} value={lastName} onChange={(e) => setLastName(e.target.value)} id={"secondName"}></input>
            </div>
            <div className={"input-wrapper"}>
                <label htmlFor={"firstName"}>Prenume:</label>
                <input className={"input"} value={firstName} onChange={(e) => setFirstName(e.target.value)} id={"firstName"}></input>
            </div>
            <div className={"input-wrapper"}>
                <label htmlFor={"email"}>Email:</label>
                <input className={"input"} value={email} onChange={(e) => setEmail(e.target.value)} id={"email"}></input>
            </div>
            <div className={"input-wrapper"}>
                <label htmlFor={"companyName"}>Numele companiei:</label>
                <input className={"input"} value={company} onChange={(e) => setCompany(e.target.value)} id={"companyName"}></input>
            </div>
            <div className={"input-wrapper"}>
                <label>Telefon:</label>
                <div className={"phoneInput-wrapper"}>
                    <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        defaultCountry={"MD"}
                    />
                </div>
            </div>
            <div className={"input-wrapper"}>
                <label htmlFor={"message"}>Mesaj:</label>
                <textarea className={"input"} value={message} onChange={(e) => setMessage(e.target.value)} id={"message"}></textarea>
            </div>
            <div className={"button-wrapper"}>
                <button className={"save-button"} onClick={submitEmail}>Trimite</button>
            </div>
        </div>
    )
  }
  
  export default ContactForm;
  