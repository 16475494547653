
function LargeNavBar(props) {
    
    const moveTo = (destination) => {
        document.getElementById(destination).scrollIntoView();
    } 

    return (
        <div className={'large-nav-bar'}>
            <div className={'nav-bar-item'}>
                <p onClick={() => moveTo('topPoint')}>Acasa</p>
            </div>
            <div className={'nav-bar-item'}>
                <p onClick={() => moveTo('aboutUs')}>Despre noi</p>
            </div>
            <div className={'nav-bar-item'}>
                <p onClick={() => moveTo('applications')}>Aplicații</p>
            </div>
            <div className={'nav-bar-item'}>
                <p onClick={() => moveTo('collaborators')}>Colaboratori</p>
            </div>
            <div className={'nav-bar-item'}>
                <p onClick={() => moveTo('contact')}>Contact</p>
            </div>
        </div>
    )
  }
  
  export default LargeNavBar;
  