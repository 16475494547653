import sticle from "../../images/sticle.png";
import materialeAdezive from "../../images/materialeAdezive.png";
import terenuriSportive from "../../images/terenuriSportive.png";
import fmf from "../../images/fmf.png";
import bicomplex from "../../images/bicomplex.png";
import fabricaSticla from "../../images/fabricaSticla.png";
import glasscompany from "../../images/glasscompany.png";
import ContactForm from "../ContactForm";

function MobileContent(props) {
    
    return (
        <div className={"mobile-content"}>
            <div className={"section"} id={'aboutUs'}>
                <p className={"section-header"}>Despre noi</p>
                <div className={"section-content"}>
                    <p className={"info-text"}>
                        Sablex-Plus este o companie care deține carieră de nisip cuarțos în satul Bîrnova, raionul Ocnița, Republica Moldova de mai mult 10 ani. Anual, reușim să aprovizionăm fabricile de producere a sticlei din Republica Moldova cu aproximativ 70% din materia primă, nisipul cuarțos, necesar pentru producerea sticlei. 
                    </p>
                </div>
            </div>
            <div className={"section"} id={'applications'}>
                <p className={"section-header"}>Aplicații ale produsului</p>
                <div className={"product-application-wrapper"}>
                    <p className={"info-text"}>
                        Nisipul cuarțos poate fi folosit pentru:
                    </p>
                    <div className={"products-wrapper"}>
                        <div className={"product-application"}>
                            <div className={"image-wrapper"}>
                                <img src={sticle}/>
                            </div>
                            <p className={"product-info"}>Producerea sticlei</p>
                        </div>
                        <div className={"product-application"}>
                            <div className={"image-wrapper"}>
                                <img src={materialeAdezive}/>
                            </div>
                            <p className={"product-info"}>Producerea materialelor adezive</p>
                        </div>
                        <div className={"product-application"}>
                            <div className={"image-wrapper"}>
                                <img src={terenuriSportive}/>
                            </div>
                            <p className={"product-info"}>Amenajarea terenurilor sportive</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"section"} id={'collaborators'}>
                <p className={"section-header"}>Colaboratori</p>
                <div className={"collaborators-wrapper"}>
                    <div className={"collaborators-image"}>
                        <img src={fmf}/>
                    </div>
                    <div className={"collaborators-image"}>
                        <img src={fabricaSticla}/>
                    </div>
                    <div className={"collaborators-image"}>
                        <img src={bicomplex}/>
                    </div>
                    <div className={"collaborators-image"}>
                        <img src={glasscompany}/>
                    </div>
                </div>
            </div>
            <div className={"section"} id={'contact'}>
                <p className={"section-header"}>Contact</p>
                <ContactForm/>
            </div>
        </div>
    )
  }
  
  export default MobileContent;
  