import axios from "axios"

const sendEmailURL = 'https://api.emailjs.com/api/v1.0/email/send';

export const sendEmail = (emailObj) => {
    const data = {
        service_id: 'service_l773e9i',
        template_id: 'template_666k92l',
        user_id: 'YoTJNHlcf90T4h25g',
        template_params: {
            form_name: emailObj.name,
            form_company: emailObj.company,
            form_email: emailObj.email,
            form_phone: emailObj.phone,
            message: emailObj.message
        }
    }
    return axios({
        url: sendEmailURL,
        method: 'post',
        contentType: 'application/json',
        data: data
    })
}