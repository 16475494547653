import { useEffect, useState } from "react";
import NavBar from "./NavBar/";
import MobileContent from "./content/MobileContent/";
import DesktopContent from "./content/DesktopContent/";
import Footer from "./Footer";

function App() {

  const [isMobile, setIsMobile] = useState(false);
    
  window.addEventListener( 'resize' , () => {
    setIsMobile(window.matchMedia("only screen and (max-width: 760px)").matches);
  });

  useEffect(() => {
    setIsMobile(window.matchMedia("only screen and (max-width: 760px)").matches);
  }, []);

  return (
    <div className="main-page" id="topPoint">
      <NavBar isMobile={isMobile}/>
      {isMobile ? <MobileContent/> : <DesktopContent/>}
      <Footer/>
    </div>
  );
}

export default App;
