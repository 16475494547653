import React from 'react';
import ReactDOM from 'react-dom';
import 'react-phone-number-input/style.css'

import './scss/index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);