import LargeNavBar from "./LargeNavBar";
import SmallNavBar from "./SmallNavBar";

function NavBar(props) {
    let isMobile = props.isMobile;
    
    if(isMobile) return <SmallNavBar/>;

    return <LargeNavBar/>;
  }
  
  export default NavBar;
  