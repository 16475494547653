import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import logo from "../images/logo.svg";

function SmallNavBar(props) {
    
    const [isOpen, setIsOpen] = useState(false);

    console.log(isOpen);

    const moveTo = (destination) => {
        setIsOpen(false);
        document.getElementById(destination).scrollIntoView();
    } 
    return (
        <div className={"small-nav-bar"}>
            
            <FontAwesomeIcon className={"burger-menu"} icon={faBars} onClick={() => setIsOpen(!isOpen)}/>
            <div className={"logo-wrapper"}>
                <img src={logo}/>
            </div>    
            {isOpen ? 
            
            <div className={"menu-content"}>
                <div className={'nav-bar-item'}>
                    <p onClick={() => moveTo('topPoint')}>Acasa</p>
                </div>
                <div className={'nav-bar-item'}>
                    <p onClick={() => moveTo('aboutUs')}>Despre noi</p>
                </div>
                <div className={'nav-bar-item'}>
                    <p onClick={() => moveTo('applications')}>Aplicații</p>
                </div>
                <div className={'nav-bar-item'}>
                    <p onClick={() => moveTo('collaborators')}>Colaboratori</p>
                </div>
                <div className={'nav-bar-item'}>
                    <p onClick={() => moveTo('contact')}>Contact</p>
                </div>
            </div>
            : null }
        </div>
    )
  }
  
  export default SmallNavBar;
  