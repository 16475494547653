import logo from "../images/logo.svg";

function Footer() {

  return (
    <div className="footer-wrapper" id="footer">
        <div className={"logo-wrapper"}>
            <img src={logo}/>
        </div>    
        <div className={"black-bar"}>

        </div>
    </div>
  );
}

export default Footer;
